import React, { lazy, Suspense } from 'react';

const IntroTextSamotvorbaWebCCh = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'IntroTextSamotvorbaWebCChLazy' */ './IntroTextSamotvorbaWebCCh'));

function IntroTextSamotvorbaWebCChLazy(props) {
    return (
        <div>
            <Suspense fallback={<div>Loading IntroTextSamotvorbaWebCCh ...</div>}>
                <IntroTextSamotvorbaWebCCh {...props} />
            </Suspense>
        </div>
    );
}

export default IntroTextSamotvorbaWebCChLazy;